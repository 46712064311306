import {
  postData,
  putData,
  isEmail,
  getQueryStringParams,
  differenceInSets,
} from "../../shared/utils";

const TREE_TYPES = {
  TEAM: "TEAM",
  MANAGEMENT: "MANAGEMENT",
  TEAMS_ONLY: "TEAMS_ONLY",
  TEAMS_WITH_IDS: "TEAMS_WITH_IDS",
  WORKDAY: "WORKDAY",
};

const STEPS = {
  TYPE_SELECTING: "type_selecting",
  MANAGING: "managing",
  DROPPING: "dropping",
  MANIPULATING: "manipulating",
  MAPPING: "mapping",
  PREVIEWING: "previewing",
  REVIEWING: "reviewing",
  SUBMITTING: "submitting",
};

// Add this helper function after imports
const debug = (...args) => {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
};

export function adminOrgCsvs(args) {
  const { currentUserEmail } = args;

  return {
    shadowing: args.shadowing || false,
    step: STEPS.TYPE_SELECTING,
    previewLoading: false,
    previewError: false,
    showInstructions: false,
    previewableImports: args.previewableImports || [],
    reviewableImports: args.reviewableImports || [],
    runningImports: args.runningImports || [],
    completedImports: args.completedImports || [],
    reviewData: null,
    isDragging: false,
    hasError: false,
    file: null,
    headers: [],
    rows: [],
    sampledRows: [],
    preprocessedTeams: [],
    fallbacksAdded: 0,
    extraLevels: [],
    activeImportId: null,
    newImport:
      getQueryStringParams().get("new") ||
      (!args.runningImports.length &&
        !args.completedImports.length &&
        !args.previewableImports.length),
    showCustomSettings: getQueryStringParams().get("custom_settings"),
    progress: 0,
    progressPayload: {},
    fields: [
      { key: "email", label: "Contributor email" },
      { key: "team_name", label: "Team name" },
      { key: "team_reference_id", label: "Team ID" },
      { key: "mgr_name", label: "Manager name" },
      { key: "mgr_email", label: "Team lead email" },
      { key: "parent_team", label: "Parent team name" },
      { key: "parent_team_dri", label: "Parent team lead email" },
      { key: "parent_team_reference_id", label: "Parent team ID" },
      { key: "grandparent_team", label: "Grandparent team name" },
      { key: "grandparent_team_dri", label: "Grandparent Team lead email" },
      { key: "grandparent_team_reference_id", label: "Grandparent Team ID" },
      { key: "members", label: "Team member emails" },
      { key: "exclude", label: "Exclude from snapshot (true/false)" },
    ],
    selectedColumns: {
      name: "",
      email: "",
      team_name: "",
      team_reference_id: "",
      mgr_name: "",
      mgr_email: "",
      parent_team: "",
      parent_team_reference_id: "",
      parent_team_dri: "",
      grandparent_team: "",
      grandparent_team_reference_id: "",
      grandparent_team_dri: "",
      exclude: "",
      members: "",
    },
    parsedRows: [],
    teamsPreview: {},
    squads: [],
    treeType: TREE_TYPES.TEAM, // "MANAGEMENT" or "TEAM" or "TEAMS_WITH_IDS"
    treeTypes: Object.keys(TREE_TYPES),
    expandedSquadsIds: {},
    customSettings: {
      minGroupSize: 0,
      prunedTeams: [],
      associationsOnly: false,
      includeManagersAsOwnTeamMembers: false,
      teamUniqueness: "teamNameParentTeamAndManagerEmail", // Possible values: teamNameOnly, teamNameAndManagerEmail, teamNameAndParentTeam, referenceIds, teamNameParentTeamAndManagerEmail
      managerTeamsAsParentTeams: false,
      teamNamePruning: "",
      resetAllTeams: false,
      userMembershipsOnly: false,
      saveForPreview: false,
    },
    employeeEmailSet: new Set(),
    showUserDiff: false,
    userDiff: [],
    showJson: false,
    showTeamsList: false,
    searchResults: {
      memberOf: [],
      managerOf: [],
    },
    hierarchyGroups: [],
    levelsSummary: [],
    creatingLevels: false,
    confirmSubmit: false,
    teamNamesStart: "",
    teamNamesEnd: "",
    teamDRIsStart: "",
    teamDRIsEnd: "",
    teamIDsStart: "",
    teamIDsEnd: "",
    manipulatedRows: [],
    teamNamesOffset: 0,
    teamDRIsOffset: 0,
    teamIDsOffset: 0,
    previewCalled: false,
    advancedSettingsExpanded: false,
    showLinkTeamModal: false,
    teamToLink: null,
    existingTeams: [],
    filteredExistingTeams: [],
    teamSearchQuery: "",
    init() {
      /**************** REMOVE THIS BEFORE SHIPPING **********************/
      setTimeout(() => {
        // NOTE!!! comment this out this before shipping
        if (process.env.NODE_ENV === "development") {
          // this.goToDropping();
        }
      }, 1500);
      /**************** REMOVE THIS BEFORE SHIPPING **********************/

      if (this.treeType == TREE_TYPES.TEAMS_ONLY) {
        this.customSettings.associationsOnly = true;
      }

      // Set initial URL state if not already set
      if (!window.location.pathname.endsWith("/preview")) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("new") === "true") {
          this.changeRoute("new");
        }
      }

      // Check for preview/{id} or review/{id} in URL and set activeImportId if found
      const pathMatch = window.location.pathname.match(
        /\/(preview|review)\/([^/]+)/,
      );

      // If path is /review with no ID, redirect to root
      if (window.location.pathname.endsWith("/review")) {
        this.changeRoute("");
        return;
      }

      if (pathMatch) {
        this.activeImportId = atob(pathMatch[2]);
        if (this.isPreviewableImport) {
          if (pathMatch[1] === "review") {
            this.startReview();
          } else {
            this.initializePreviewableImport(this.activeImportId);
          }
        } else {
          this.previewError = true;
          this.activeImportId = null;
          this.goToTypeSelecting();
          setTimeout(() => {
            this.previewError = false;
          }, 5000);
        }
      }
    },
    get step1Active() {
      return this.mapping || this.manipulating || this.dropping;
    },
    get step1Complete() {
      return (
        !(this.mapping || this.manipulating || this.dropping) &&
        (this.reviewing || this.previewing)
      );
    },
    get step2Active() {
      return this.previewing;
    },
    get step2Complete() {
      return !this.previewing && this.reviewing;
    },
    get step3Active() {
      return this.reviewing || this.submitting;
    },
    get step3Complete() {
      return false; //!this.reviewing && !this.submitting;
    },
    get friendlyTreeType() {
      switch (this.treeType) {
        case TREE_TYPES.TEAM:
          return "Team Hierarchy";
        case TREE_TYPES.TEAMS_WITH_IDS:
          return "Team Hierarchy with IDs";
        case TREE_TYPES.MANAGEMENT:
          return "Management Hierarchy";
        case TREE_TYPES.WORKDAY:
          return "Workday RaaS";
        default:
          return "Team Hierarchy";
      }
    },
    get typeSelecting() {
      return (
        this.step == STEPS.TYPE_SELECTING &&
        !this.activeImportId &&
        !this.previewIsLoading
      );
    },
    get managing() {
      return (
        (this.step == STEPS.MANAGING || this.step == STEPS.DROPPING) &&
        !this.previewIsLoading
      );
    },
    get dropping() {
      return this.step == STEPS.DROPPING && !this.previewIsLoading;
    },
    get mapping() {
      return this.step == STEPS.MAPPING && !this.previewIsLoading;
    },
    get manipulating() {
      return this.step == STEPS.MANIPULATING && !this.previewIsLoading;
    },
    get previewing() {
      return this.step == STEPS.PREVIEWING && !this.previewIsLoading;
    },
    get reviewing() {
      return this.step == STEPS.REVIEWING;
    },
    get submitting() {
      return this.step == STEPS.SUBMITTING;
    },
    get isPreviewableImport() {
      return (
        !!this.previewableImports.find((i) => i.id == this.activeImportId) ||
        !!this.reviewableImports.find((i) => i.id == this.activeImportId)
      );
    },
    get activePreviewableImport() {
      return (
        this.previewableImports.find((i) => i.id == this.activeImportId) ||
        this.reviewableImports.find((i) => i.id == this.activeImportId)
      );
    },
    get activeReviewableImport() {
      return this.reviewableImports.find((i) => i.id == this.activeImportId);
    },
    set activePreviewableImport(imp) {
      this.previewableImports = this.previewableImports.map((i) =>
        i.id == this.activeImportId ? imp : i,
      );
    },
    set activeReviewableImport(imp) {
      this.reviewableImports = this.reviewableImports.map((i) =>
        i.id == this.activeImportId ? imp : i,
      );
    },
    get columnsAreValid() {
      // TODO: More logic needed here for validity.
      let valid = false;

      const row = this.sampleRandomRows(1)?.[0];
      const email = row && row[this.selectedColumns["email"]];

      if (this.treeType == TREE_TYPES.TEAM) {
        if (
          isEmail(email) &&
          row[this.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      } else if (this.treeType == TREE_TYPES.MANAGEMENT) {
        if (isEmail(email) && isEmail(row[this.selectedColumns["mgr_email"]]))
          valid = true;
      } else if (this.treeType == TREE_TYPES.TEAMS_ONLY) {
        const manager_email = row && row[this.selectedColumns["mgr_email"]];
        if (
          isEmail(manager_email) &&
          row[this.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      } else if (this.treeType == TREE_TYPES.TEAMS_WITH_IDS) {
        if (
          row &&
          row[this?.selectedColumns["team_reference_id"]]?.length > 0 &&
          row[this?.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      }

      return valid;
    },
    get treeSquads() {
      this.squads.map((s) => {
        return s;
      });

      const squadsToReturn = this.squads?.filter((s) => {
        if (s.existing_id) {
          // console.log(`squad:`, s);
          // debug(`team ${s.team} has existing_id: ${s.existing_id}`);
        }
        const value =
          s.depth == 0 ||
          s.expanded == true ||
          this.expandedSquadsIds[s.parent_id] == true;

        return value;
      });

      return squadsToReturn;
    },
    get topLevelSquads() {
      return this.squads?.filter((s) => s.depth == 0);
    },
    get teamsMissingFromList() {
      const missingTeams = this.preprocessedTeams?.filter(
        (t) => !this.squads.find((s) => s.team === t.team),
      );
      return missingTeams;
    },
    get previewIsLoading() {
      if (this.reviewing) return false;

      const result =
        (this.activeImportId &&
          !Object.keys(this.progressPayload).length &&
          !this.isPreviewableImport) ||
        (this.previewLoading && !this.squads.length);
      return result;
    },
    get duplicateTeamNames() {
      const teamNameMap = new Map();

      this.squads.forEach((squad) => {
        const teamName = squad.team;
        const managerEmail = squad.mgr_email;
        const parentTeam = this.squads.find(
          (s) => s.temp_id === squad.parent_id,
        )?.team;

        if (!teamNameMap.has(teamName)) {
          teamNameMap.set(teamName, new Map());
        }

        const teamInfo = teamNameMap.get(teamName);
        if (!teamInfo.has(managerEmail)) {
          teamInfo.set(managerEmail, new Set());
        }
        if (parentTeam) {
          teamInfo.get(managerEmail).add(parentTeam);
        }
      });

      const duplicates = [];

      teamNameMap.forEach((managerInfo, teamName) => {
        if (managerInfo.size > 1) {
          const managers = Array.from(managerInfo.entries()).map(
            ([email, parents]) => ({
              email,
              parentTeams: Array.from(parents),
            }),
          );
          duplicates.push({
            team: teamName,
            managers: managers.map((m) =>
              m.parentTeams.length
                ? `${m.email} (Parent: ${m.parentTeams.join(", ")})`
                : m.email,
            ),
          });
        }
      });

      return duplicates;
    },
    get currentCompletionReport() {
      return (
        this.progressPayload?.completion_report ||
        this.activeReviewableImport?.completion_report
      );
    },
    resetFile() {
      this.file = null;
      this.isDragging = false;
      this.hasError = false;
      this.errorMessage = "";
      this.rows = [];
      this.sampledRows = [];
      this.headers = [];
    },
    goToManaging() {
      this.resetFile();
      this.step = STEPS.MANAGING;
      this.newImport = false;
    },
    goToTypeSelecting() {
      this.step = STEPS.TYPE_SELECTING;
      this.newImport = true;
      this.changeRoute("new");
    },
    goToDropping() {
      this.resetFile();
      this.step = STEPS.DROPPING;
      this.newImport = true;
      this.changeRoute("new");
    },
    goToMapping() {
      this.step = STEPS.MAPPING;
      console.log("this.rows:", this.rows);
      // Start of Selection
      const rowsWithMembers = this.sampledRows.filter(
        (row) => row.members !== null,
      );
      console.log("this.sampledRows with members:", rowsWithMembers);
      this.changeRoute("new");
    },
    goToPreviewing() {
      debug(`going To previewing:`);
      if (this.reviewing && this.activePreviewableImport) {
        this.initializePreviewableImport(this.activeImportId);
      }
      this.step = STEPS.PREVIEWING;
    },
    goToReviewing() {
      debug(`goToReviewing...`);
      this.step = STEPS.REVIEWING;
      this.changeRoute("review");
    },
    goToSubmitting() {
      debug(`goToSubmitting...`);
      this.step = STEPS.SUBMITTING;
    },
    setActiveImport(importId) {
      this.activeImportId = importId;
    },
    confirmSubmission() {
      this.confirmSubmit = true;
    },
    initializePreviewableImport() {
      debug(`initializePreviewableImport...`, this.activePreviewableImport);
      if (!this.activePreviewableImport) this.goToManaging();
      this.treeType =
        this.activePreviewableImport?.tree_type || TREE_TYPES.TEAM;
      this.customSettings.teamUniqueness =
        this.activePreviewableImport.team_uniqueness ||
        "teamNameAndManagerEmail";
      // Get a sample row from rows_json
      const sampleRow = this.activePreviewableImport.rows_json[0];
      if (sampleRow) {
        // Find all extra_level_x fields by checking keys
        const extraLevels = Object.keys(sampleRow)
          .filter((key) => key.match(/^extra_level_\d+$/))
          .map((key) => parseInt(key.replace("extra_level_", "")))
          .sort((a, b) => a - b);

        extraLevels.forEach((level) => {
          this.addLevel(level);
        });
      }
      this.preview();
    },
    addLevel() {
      const newLevel = this.extraLevels.length + 1;
      this.extraLevels.push(newLevel);

      // Add the new level's columns to selectedColumns
      this.selectedColumns[`extra_level_${newLevel}`] =
        `extra_level_${newLevel}`;
      this.selectedColumns[`extra_level_${newLevel}_dri`] =
        `extra_level_${newLevel}_dri`;
      this.selectedColumns[`extra_level_${newLevel}_reference_id`] =
        `extra_level_${newLevel}_reference_id`;
    },
    goBack() {
      if (this.reviewing) {
        this.goToPreviewing();
      } else if (this.previewing && this.isPreviewableImport) {
        this.activeImportId = null;
        this.changeRoute("");
        this.goToManaging();
      } else if (this.previewing && !this.isPreviewableImport) {
        this.goToManaging();
      } else if (this.mapping) {
        this.goToDropping();
      } else if (this.dropping) {
        this.step = STEPS.MANAGING;
        if (this.activeImportId) {
          this.newImport = false;
        }
        this.activeImportId = null;
        this.changeRoute("");
      }
    },
    changeRoute(path) {
      const currentUrl = new URL(window.location.href);

      // Check if current path is /preview/:id and new path is "review"
      const previewMatch = currentUrl.pathname.match(/\/preview\/([^/]+)/);
      if (previewMatch && path === "review") {
        const newUrl = new URL(
          `/admin/org_csvs/review/${previewMatch[1]}`,
          window.location.origin,
        );
        currentUrl.searchParams.forEach((value, key) => {
          newUrl.searchParams.set(key, value);
        });
        window.history.pushState({}, "", newUrl.toString());
        return;
      }

      if (path == "review") {
        debug("activeReviewableImport", this.activeReviewableImport);
        if (this.activeReviewableImport?.encoded_id) {
          const newUrl = new URL(
            `/admin/org_csvs/review/${this.activeReviewableImport.encoded_id}`,
            window.location.origin,
          );
          currentUrl.searchParams.forEach((value, key) => {
            newUrl.searchParams.set(key, value);
          });
          window.history.pushState({}, "", newUrl.toString());
          return;
        }
      }

      let newUrl = new URL(`/admin/org_csvs/${path}`, window.location.origin);
      currentUrl.searchParams.forEach((value, key) => {
        newUrl.searchParams.set(key, value);
      });
      window.history.pushState({}, "", newUrl.toString());
    },
    searchUsers(email) {
      debug(`searching for email:`, email);
      if (!email || email.length < 3) return [];

      const searchTerm = email.toLowerCase();
      const memberOfResults = this.squads?.filter((squad) =>
        squad.members?.some((member) =>
          member.toLowerCase().includes(searchTerm),
        ),
      );

      const managerOfResults = this.squads?.filter((squad) =>
        squad.mgr_email?.toLowerCase().includes(searchTerm),
      );

      // Add parent_team field to managerOfResults by looking up parent_id
      managerOfResults.forEach((team) => {
        if (team.parent_id) {
          const parentTeam = this.squads?.find(
            (s) => s.temp_id === team.parent_id,
          );
          if (parentTeam) {
            team.grandparent_team = parentTeam.parent_team_name;
          }
        }
      });

      // Add parent_team field to memberOfResults by looking up parent_id
      memberOfResults.forEach((team) => {
        if (team.parent_id) {
          const parentTeam = this.squads?.find(
            (s) => s.temp_id === team.parent_id,
          );
          if (parentTeam) {
            team.found_parent_team = parentTeam.team;
            team.grandparent_team = parentTeam.parent_team_name;
          }
        }
      });

      this.searchResults = {
        memberOf: memberOfResults,
        managerOf: managerOfResults,
      };
      debug(`this.searchResults:`, this.searchResults);
    },
    childTeamsOf(squad) {
      const childSquads = this.squads?.filter(
        (s) => s.parent_id == squad.temp_id,
      );
      return childSquads;
    },
    formatDate(date) {
      const d = new Date(date);
      // Get the hours and minutes
      const hours = d.getHours();
      const minutes = d.getMinutes().toString().padStart(2, "0");
      const seconds = d.getSeconds().toString().padStart(2, "0");

      // Determine AM/PM
      const ampm = hours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Format date using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(d);

      // Return the formatted string
      return `${formattedHours}:${minutes}:${seconds}${ampm} on ${formattedDate}`;
    },
    handleDrop(event) {
      this.hasError = false;
      event?.preventDefault();

      // Handle both drag & drop and file input
      const file =
        event.type === "drop"
          ? event.dataTransfer.files[0]
          : event.target.files[0];

      this.file = file;
      this.isDragging = false;

      // After validating the file, check for supervisory_org columns
      this.validateFile(() => {
        // Check if any headers contain 'supervisory_org' (case insensitive)
        const hasSupervisoryOrg = this.headers.some((header) =>
          header.toLowerCase().includes("supervisory_org"),
        );

        if (hasSupervisoryOrg || this.treeType == TREE_TYPES.WORKDAY) {
          this.goToManipulating();
        } else {
          this.goToMapping();
        }
      });
    },
    processFile(callback) {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.parseCSV(csvContent, callback);
        };
        reader.readAsText(this.file);
      }
    },
    validateFile(callback) {
      this.errorMessage = "";
      if (this.file) {
        // Check the MIME type or extension
        const mimeType = this.file.type;
        const fileName = this.file.name;

        // Validate MIME type or file extension
        if (
          mimeType !== "text/csv" &&
          mimeType !== "application/vnd.ms-excel" &&
          !fileName.endsWith(".csv")
        ) {
          this.errorMessage = "Invalid file type. Please upload a CSV file.";
          this.hasError = true;
          this.file = null;
          return;
        }

        this.processFile(callback); // Pass the callback to processFile
      }
    },
    async parseCSV(csvContent, callback) {
      const Papa = await import("papaparse");
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          this.headers = result.meta.fields;
          console.log(`headers:`, this.headers);
          // Replace #REF! with empty strings in each row
          this.rows = result.data.map((row) => {
            const cleanedRow = {};
            for (const key in row) {
              cleanedRow[key] =
                row[key] === "#REF!" ||
                row[key] === "#N/A" ||
                row[key] === "null"
                  ? ""
                  : row[key];
            }
            return cleanedRow;
          });
          this.sampledRows = this.sampleRandomRows();

          // Auto-map columns based on possible field names
          this.autoMapColumns();

          // Execute callback if provided
          if (callback) callback();
        },
        skipEmptyLines: true,
      });
    },
    autoMapColumns() {
      const possibleFieldMappings = {
        name: ["name", "full_name", "contributor_name"],
        email: [
          "email",
          "contributor_email",
          "employee_email",
          "user_email",
          "contributor email",
          "email - work",
        ],
        team_name: [
          "team_name",
          "direct_team",
          "team",
          "squad_name",
          "team name",
        ],
        team_reference_id: [
          "team_reference_id",
          "team_id",
          "squad_id",
          "team_source_id",
        ],
        mgr_name: [
          "mgr_name",
          "manager_name",
          "team_lead_name",
          "lead_name",
          "team dri",
        ],
        mgr_email: [
          "mgr_email",
          "manager_email",
          "team_lead_email",
          "lead_email",
          "manager email",
          "team dri",
        ],
        parent_team: [
          "parent_team",
          "parent_team_name",
          "parent_squad",
          "team group name",
          "team group name (optional)",
        ],
        parent_team_reference_id: [
          "parent_team_reference_id",
          "parent_team_id",
          "parent_squad_id",
          "parent_source_id",
        ],
        parent_team_dri: [
          "parent_team_dri",
          "parent_team_manager",
          "parent_team_lead",
          "team group dri",
        ],
        grandparent_team: [
          "grandparent_team",
          "grandparent_team_name",
          "grandparent_squad",
          "area/value stream",
        ],
        grandparent_team_reference_id: [
          "grandparent_team_reference_id",
          "grandparent_team_id",
          "grandparent_squad_id",
        ],
        grandparent_team_dri: [
          "grandparent_team_dri",
          "grandparent_team_manager",
          "grandparent_team_lead",
          "area/value stream dri",
        ],
        members: ["members", "team_members", "team_member_emails"],
      };

      // Add mappings for any extra levels found in the data
      if (this.extraLevels?.length > 0) {
        this.extraLevels.forEach((level) => {
          const levelNum = level + 2; // Since extra levels start after grandparent
          possibleFieldMappings[`extra_level_${level}`] = [
            `extra_level_${level}`,
            `level_${levelNum}_team`,
            `level_${levelNum}_name`,
            `level_${levelNum}`,
          ];
          possibleFieldMappings[`extra_level_${level}_dri`] = [
            `extra_level_${level}_dri`,
            `level_${levelNum}_manager`,
            `level_${levelNum}_dri`,
            `level_${levelNum}_lead`,
          ];
          possibleFieldMappings[`extra_level_${level}_reference_id`] = [
            `extra_level_${level}_reference_id`,
            `level_${levelNum}_id`,
            `level_${levelNum}_reference`,
          ];
        });
      }

      this.selectedColumns = {};
      for (const [targetField, possibleFields] of Object.entries(
        possibleFieldMappings,
      )) {
        const foundField = this.headers.find((header) => {
          return possibleFields.includes(header.toLowerCase());
        });
        if (foundField) {
          this.selectedColumns[targetField] = foundField;
        }
      }
    },
    sampleRandomRows(n = 20) {
      if (this.rows.length > 0) {
        // Shuffle the rows and take a random sample of 20 rows
        const shuffled = [...this.rows].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, n);
      }
    },
    async preview() {
      this.previewLoading = true;
      this.collapseAll();
      // this.step = STEPS.PREVIEWING;
      // Force a UI update before heavy processing begins
      await new Promise((resolve) => setTimeout(resolve, 0));

      if (this.isPreviewableImport) {
        // Map all fields from the previewable import's rows
        this.rows = this.activePreviewableImport.rows_json.map((row) => {
          // Create a base object with standard fields
          const mappedRow = {
            email: row.email?.toLowerCase(),
            team: row.team,
            name: row.name,
            team_reference_id: row.team_reference_id,
            mgr_name: row.mgr_name,
            mgr_email: row.mgr_email?.toLowerCase(),
            parent_team: row.parent_team,
            parent_team_reference_id: row.parent_team_reference_id,
            parent_team_dri: row.parent_team_dri?.toLowerCase(),
            grandparent_team: row.grandparent_team,
            grandparent_team_reference_id: row.grandparent_team_reference_id,
            grandparent_team_dri: row.grandparent_team_dri?.toLowerCase(),
            members: row.members,
            exclude: row.exclude,
          };

          // Add any extra level fields that exist in the row
          this.extraLevels.forEach((level) => {
            mappedRow[`extra_level_${level}`] = row[`extra_level_${level}`];
            mappedRow[`extra_level_${level}_dri`] =
              row[
                this.selectedColumns[`extra_level_${level}_dri`]
              ]?.toLowerCase();
            mappedRow[`extra_level_${level}_reference_id`] =
              row[this.selectedColumns[`extra_level_${level}_reference_id`]];
          });

          return mappedRow;
        });

        // Auto-map columns for previewable imports
        if (this.rows.length > 0) {
          const sampleRow = this.rows[0];
          this.headers = Object.keys(sampleRow);
          this.autoMapColumns();
        }
      }

      try {
        const managersSet = new Set();

        const employees = this.rows.map((row) => {
          if (
            row[this.selectedColumns["email"]] &&
            row[this.selectedColumns["exclude"]] != "TRUE"
          ) {
            this.employeeEmailSet.add(
              row[this.selectedColumns["email"]]?.toLowerCase(),
            );
          } else if (row[this.selectedColumns["members"]]) {
            const members = row[this.selectedColumns["members"]].split(",");
            members.forEach((member) => {
              if (member.trim()) {
                this.employeeEmailSet.add(member.trim().toLowerCase());
              }
            });
          }

          const extraLevelsData = this.extraLevels.reduce(
            (acc, level) => ({
              ...acc,
              [`extra_level_${level}`]:
                row[this.selectedColumns[`extra_level_${level}`]],
              [`extra_level_${level}_dri`]:
                row[
                  this.selectedColumns[`extra_level_${level}_dri`]
                ]?.toLowerCase(),
              [`extra_level_${level}_reference_id`]:
                row[this.selectedColumns[`extra_level_${level}_reference_id`]],
            }),
            {},
          );

          return {
            email: row[this.selectedColumns["email"]]?.toLowerCase(),
            name: row[this.selectedColumns["team_name"]],
            team: row[this.selectedColumns["team_name"]],
            team_reference_id: row[this.selectedColumns["team_reference_id"]],
            mgr_name: row[this.selectedColumns["mgr_name"]], // TODO: fallback logic here to traverse back through the fallbacks if they don't exist
            mgr_email: row[this.selectedColumns["mgr_email"]]?.toLowerCase(),
            parent_team: row[this.selectedColumns["parent_team"]],
            parent_team_reference_id:
              row[this.selectedColumns["parent_team_reference_id"]],
            parent_team_dri:
              this.selectedColumns["parent_team_dri"] === "current_user_email"
                ? currentUserEmail
                : row[this.selectedColumns["parent_team_dri"]]?.toLowerCase(),
            grandparent_team: row[this.selectedColumns["grandparent_team"]],
            grandparent_team_reference_id:
              row[this.selectedColumns["grandparent_team_reference_id"]],
            grandparent_team_dri:
              row[this.selectedColumns["grandparent_team_dri"]]?.toLowerCase(),
            exclude: row[this.selectedColumns["exclude"]] == "TRUE",
            members: row[this.selectedColumns["members"]] || "",
            ...extraLevelsData,
          };
        });

        // Check first row for any reference IDs and set teamUniqueness accordingly
        if (employees.length > 0) {
          const firstRow = employees[0];
          if (
            !this.previewCalled &&
            (firstRow.team_reference_id ||
              firstRow.parent_team_reference_id ||
              firstRow.grandparent_team_reference_id) &&
            !this.activeImportId
          ) {
            this.customSettings.teamUniqueness = "referenceIds";
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 0));

        this.parsedRows = employees;
        debug(`employees:`, employees);

        if (this.isPreviewableImport) {
          if (this.reviewing) {
            this.changeRoute(
              `review/${this.activeReviewableImport.encoded_id}`,
            );
          } else {
            this.changeRoute(
              `preview/${this.activePreviewableImport.encoded_id}`,
            );
          }
        } else if (this.activeImportId && !this.isPreviewableImport) {
          this.goBack();
        }

        if (
          this.treeType === TREE_TYPES.MANAGEMENT &&
          !this.selectedColumns["mgr_email"]
        ) {
          this.treeType = TREE_TYPES.TEAM;
        }

        const pinterestHierarchy = null;

        this.preprocessedTeams =
          this.treeType == TREE_TYPES.TEAM ||
          this.treeType == TREE_TYPES.TEAMS_ONLY ||
          this.treeType == TREE_TYPES.TEAMS_WITH_IDS
            ? this.preprocessTeams(
                this.parsedRows,
                managersSet,
                this.treeType == TREE_TYPES.TEAMS_ONLY,
              )
            : this.preprocessManagerTeams(this.parsedRows);

        debug(`this.preprocessedTeams:`, this.preprocessedTeams);

        await new Promise((resolve) => setTimeout(resolve, 0));

        if (!pinterestHierarchy) {
          const result =
            this.treeType == TREE_TYPES.MANAGEMENT
              ? this.buildOrgHierarchy(this.parsedRows)
              : this.buildTeamHierarchy(this.preprocessedTeams);
          this.teamsPreview = result;
          console.log(`result:`, result);
        } else {
          this.teamsPreview = pinterestHierarchy;
        }

        await new Promise((resolve) => setTimeout(resolve, 0));

        const flattenedSquads =
          this.treeType == TREE_TYPES.MANAGEMENT
            ? this.flattenOrgHierarchy(this.teamsPreview)
            : this.flattenTeamHierarchy(
                this.teamsPreview,
                this.preprocessedTeams,
              ); // May need a different flattening process based on the tree type

        this.squads = flattenedSquads;

        if (this.isPreviewableImport && this.currentCompletionReport) {
          this.updateSquadsWithExistingIds(
            this.currentCompletionReport.updated_teams,
          );
        }

        const diff = differenceInSets(
          this.employeeEmailSet,
          this.uniqueMembers(this.squads),
        );
        this.userDiff = Array.from(diff);
        this.goToPreviewing();
      } catch (error) {
        console.error("Error in preview:", error);
        throw error;
      } finally {
        this.previewLoading = false;
        if (!this.previewCalled) {
          this.previewCalled = true;
        }
      }
    },
    buildOrgHierarchy(employees) {
      const customSettings = this.customSettings;
      // Create a map of all employees by their email
      const employeeMap = new Map();
      employees.forEach((emp) => {
        // Add an entry for the employee
        employeeMap.set(emp.email, emp);
        // Add an entry for the manager if it doesn't exist
        if (emp.mgr_email && !employeeMap.has(emp.mgr_email)) {
          employeeMap.set(emp.mgr_email, {
            email: emp.mgr_email,
            name: emp.mgr_name,
            mgr_email: null,
            mgr_name: null,
          });
        }
      });

      // Enrich employee map with names from manager data
      employees.forEach((emp) => {
        if (emp.mgr_email && emp.mgr_name) {
          const manager = employeeMap.get(emp.mgr_email);
          if (manager && !manager.name) {
            manager.name = emp.mgr_name;
            employeeMap.set(emp.mgr_email, manager);
          }
        }
      });

      // Find the top-level manager(s)
      const topManagers = Array.from(employeeMap.values()).filter(
        (emp) => !emp.mgr_email || emp.mgr_email === "",
      );
      console.log(`topManagers:`, topManagers);

      let teamIdCounter = 1;

      // Function to recursively build the hierarchy
      function buildHierarchy(manager, depth = 1, parent_id) {
        const temp_id = `T${teamIdCounter++}`;
        const subordinates = employees.filter(
          (emp) => emp.mgr_email === manager.email,
        );

        const getName = (email) => {
          const emp = employeeMap.get(email);
          return emp && emp.name ? emp.name : email;
        };

        // Build subTeams first so we can check if they exist
        const subTeams = subordinates
          .map((sub) => buildHierarchy(sub, depth + 1, temp_id))
          .filter(Boolean); // Remove null/undefined results

        let members = [...subordinates.map((sub) => sub.email)];
        if (customSettings.includeManagersAsOwnTeamMembers) {
          members.push(manager.email);
        }

        const teamName =
          manager.name ||
          (subTeams.length > 0 ? getName(manager.email) : manager.email);

        // Only return a team if it has members or valid subTeams
        if (members.length === 0 && subTeams.length === 0) {
          return null;
        }

        return {
          name: teamName,
          team: teamName,
          email: manager.email,
          mgr_email: manager.email,
          parent_team: manager.parent_team,
          members,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          subTeams: subTeams,
        };
      }

      // Build the hierarchy for each team
      let hierarchy = topManagers.map((team) => {
        const temp_id = `T${teamIdCounter++}`;

        const objToReturn = {
          team: team.name,
          name: team.name,
          members: [],
          email: team.email,
          depth: 0,
          temp_id: temp_id,
          is_parent: true,
          subTeams: [buildHierarchy(team, 1, temp_id)],
        };

        objToReturn.subTeams = objToReturn.subTeams.filter((team) => {
          if (
            team.is_parent ||
            team.members.length > 0 ||
            team.name ||
            team.subTeams.length > 0
          ) {
            return true;
          }
          objToReturn.members = [...objToReturn.members, team.email];
          return false;
        });

        //return objToReturn;
        // NOTE: be mindful of all the logic I just removed here.
        return buildHierarchy(team, 0, temp_id);
      });

      // Apply pruning after the hierarchy is built
      if (customSettings.minGroupSize > 0) {
        const prunedTeams = [];

        function pruneSmallTeams(node, parent = null) {
          if (!node) return null;

          // Process subTeams first
          if (node.subTeams && node.subTeams.length > 0) {
            node.subTeams = node.subTeams
              .map((subTeam) => pruneSmallTeams(subTeam, node))
              .filter((subTeam) => subTeam !== null);
          }

          // After processing subTeams, check if current node is a leaf
          const isLeaf = !node.subTeams || node.subTeams.length === 0;

          if (isLeaf && node.members.length < customSettings.minGroupSize) {
            if (parent) {
              // Move members up to parent team
              parent.members = [...parent.members, ...node.members];

              // Record the pruned team
              prunedTeams.push({
                removedTeam: node.team,
                movedMemberCount: node.members.length,
                movedMembers: node.members,
                newSourceTeam: parent.team,
              });

              // Remove this node by returning null
              return null;
            }
          }

          // Update is_parent flag based on subTeams
          node.is_parent = node.subTeams && node.subTeams.length > 0;
          return node;
        }

        // Apply pruning to the hierarchy
        hierarchy = hierarchy
          .map((team) => pruneSmallTeams(team))
          .filter((team) => team !== null);

        this.customSettings.prunedTeams = prunedTeams.sort((a, b) =>
          a.removedTeam.localeCompare(b.removedTeam),
        );
      }

      return hierarchy.sort((a, b) => {
        // Sort by is_parent first
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        // If is_parent is the same, sort alphabetically by team
        return a.team.localeCompare(b.team);
      });
    },
    flattenTeamHierarchy(hierarchy, preprocessedTeams) {
      const flatArray = [];
      const getTeamKey = this.teamKey.bind(this);
      const customSettings = this.customSettings;

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        // Lookup the node in preprocessedTeams and ensure the members list is the same
        const matchingTeam = preprocessedTeams.find(
          (preprocessedTeam) =>
            getTeamKey(preprocessedTeam) === getTeamKey(node),
        );

        let members = node.members;

        // use the members from the preprocessed team if no other manipulation has occurred
        if (matchingTeam && customSettings.minGroupSize == 0) {
          members = matchingTeam.members;
        }

        flatArray.push({
          team: node?.team,
          name: node.name,
          reference_id: node.reference_id,
          email: node.email,
          mgr_email: node.mgr_email,
          temp_id: node.temp_id,
          parent_team_reference_id: node.parent_team_reference_id,
          parent_id: parentId,
          is_parent: node.is_parent,
          depth: node.depth,
          members: members.filter(
            (member) =>
              member && typeof member === "string" && member.trim().length > 0,
          ),
          parent_team_name: node.parent_team,
        });

        // Recursively flatten the subTeams (children)
        node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    flattenOrgHierarchy(hierarchy) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        // Lookup the node in preprocessedTeams and ensure the members list is the same

        // TODO: Almost there here. this is not quite the right condition
        // basically what's happening is that it's flattening a separate team for each contributor
        // it only needs to do it for teams with people
        if (node?.members?.length > 0 || node?.subTeams?.length > 0) {
          flatArray.push({
            name: node.name,
            email: node.email,
            mgr_email: node.mgr_email,
            team: node?.team,
            temp_id: node.temp_id,
            parent_id: parentId,
            is_parent: node.is_parent,
            depth: node.depth,
            members: node.members,
            subTeamsCount: node.subTeams.length,
            parent_team_name: node.parent_team,
          });

          // Recursively flatten the subTeams (children)
          node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
        }
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    preprocessManagerTeams(employees) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        const teamKey = `${emp.mgr_name}-${emp.mgr_email}`;

        if (!teamMap.has(teamKey)) {
          // Add the team if it doesn't already exist
          teamMap.set(teamKey, {
            team: emp.mgr_name,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            parent_team: emp.parent_team || null,
            parent_team_dri: emp.parent_team_dri || null,
            grandparent_team: emp.grandparent_team || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            members: [],
          });
        }

        // Adding only direct IC's team
        // if (!managersSet.has(emp.email)) {
        teamMap.get(teamKey).members.push(emp.email);
        // }
      });

      // Second Pass: Assign parent_team based on mgr_email
      teamMap.forEach((teamValue) => {
        if (teamValue.mgr_email) {
          // Find the manager's employee record
          const manager = employees.find(
            (emp) => emp.email === teamValue.mgr_email,
          );
          if (manager && manager.team) {
            // Assign the manager's team as the parent_team
            teamValue.parent_team = manager.team;
          }
        }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    preprocessTeams(employees, managersSet, isTeamsOnly = false) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        let teamName = emp.team;
        let parentTeamName = emp.parent_team;
        let grandparentTeamName = emp.grandparent_team;

        // Strip pruning strings if configured
        if (this.customSettings.teamNamePruning) {
          const pruningTerms = this.customSettings.teamNamePruning
            .split(",")
            .map((term) => term.trim());
          pruningTerms.forEach((term) => {
            if (teamName) teamName = teamName.replace(term, "").trim();
            if (parentTeamName)
              parentTeamName = parentTeamName.replace(term, "").trim();
            if (grandparentTeamName)
              grandparentTeamName = grandparentTeamName
                .replace(term, "")
                .trim();
          });
        }

        const empWithPrunedNames = {
          ...emp,
          reference_id: emp.team_reference_id || null,
          team: teamName,
          parent_team: parentTeamName,
          grandparent_team: grandparentTeamName,
        };

        let empToUse = empWithPrunedNames;

        if (!teamMap.has(this.teamKey(empWithPrunedNames))) {
          // Add the team if it doesn't already exist
          const extraLevelsData = this.extraLevels.reduce(
            (acc, level) => ({
              ...acc,
              [`extra_level_${level}`]: emp[`extra_level_${level}`] || null,
              [`extra_level_${level}_dri`]:
                emp[`extra_level_${level}_dri`] || null,
              [`extra_level_${level}_reference_id`]:
                emp[`extra_level_${level}_reference_id`] || null,
            }),
            {},
          );

          // Start of Selection
          let members = [];
          if (emp.members) {
            members = emp.members
              .split(",")
              .map((member) => member.trim().toLowerCase())
              .filter((member) => member); // Filter out any falsy values, including undefined
          }

          empToUse = {
            team: teamName,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            reference_id: emp.team_reference_id || null,
            parent_team: parentTeamName || null,
            parent_team_dri: emp.parent_team_dri || null,
            parent_team_reference_id: emp.parent_team_reference_id || null,
            grandparent_team: grandparentTeamName || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            grandparent_team_reference_id:
              emp.grandparent_team_reference_id || null,
            members,
            ...extraLevelsData,
          };

          teamMap.set(this.teamKey(empToUse), empToUse);
        }

        if (!isTeamsOnly && !emp.exclude && emp.email) {
          const team = teamMap.get(this.teamKey(empToUse));
          team.members.push(emp.email);
        }
      });

      if (this.customSettings.managerTeamsAsParentTeams) {
        // Iterate through each team to find parent teams based on manager membership
        teamMap.forEach((teamValue, teamKey) => {
          // Only process teams that don't already have a parent team assigned
          if (!teamValue.parent_team && teamValue.mgr_email) {
            // Search through all teams to find where the manager is a member
            teamMap.forEach((potentialParentTeam, potentialParentKey) => {
              if (
                potentialParentKey !== teamKey && // Don't match self
                potentialParentTeam.members.includes(teamValue.mgr_email)
              ) {
                // Found the team where the manager is a member - set as parent
                teamValue.parent_team = potentialParentTeam.team;
                teamValue.parent_team_dri = potentialParentTeam.mgr_email;
              }
            });
          }
        });
      }

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    teamKey(team) {
      switch (this?.customSettings?.teamUniqueness) {
        case "referenceIds":
          if (team.reference_id || team.team_reference_id) {
            return team.reference_id || team.team_reference_id;
          }
          break;
        case "teamNameOnly":
          return team.team;
        case "teamNameAndParentTeam":
          return `${team.team}-${team?.parent_team || null}`;
        case "teamNameParentTeamAndManagerEmail":
          return `${team.team}-${team?.parent_team || null}-${team.mgr_email || null}`;
        case "teamNameAndManagerEmail":
        default:
          return `${team.team}-${team.mgr_email}`;
      }
    },
    enrichReferenceIdTeamsWithParentNames(teams) {
      // Create a reference ID to team name mapping
      const referenceIdToTeamMap = new Map();
      teams.forEach((team) => {
        if (team.reference_id && team.team) {
          referenceIdToTeamMap.set(team.reference_id, team.team);
        }
      });

      // Enrich teams with parent team names based on reference IDs
      return teams.map((team) => {
        if (team.parent_team_reference_id && !team.parent_team) {
          const parentTeamName = referenceIdToTeamMap.get(
            team.parent_team_reference_id,
          );
          if (parentTeamName) {
            return {
              ...team,
              parent_team: parentTeamName,
            };
          }
        }
        return team;
      });
    },
    buildTeamHierarchy(teams) {
      const getTeamKey = this.teamKey.bind(this);

      // Only enrich teams with parent names if using reference IDs for uniqueness
      if (this.customSettings.teamUniqueness === "referenceIds") {
        teams = this.enrichReferenceIdTeamsWithParentNames(teams);
      }

      // Step 1: Create a map for quick team lookup by team key
      const teamMap = new Map();
      teams.forEach((team) => {
        teamMap.set(getTeamKey(team), team);
      });

      const hasGrandparentTeams = teams.some((team) => team.grandparent_team);
      // Step 2: Insert missing parent teams
      const allTeams =
        this.extraLevels.length > 0
          ? this.generateAllTeams(teams)
          : this.insertMissingParents(teams, teamMap);

      // Step 3: Identify Top-Level Teams
      const topTeamsSet = new Set();

      allTeams.forEach((team) => {
        if (this.customSettings.teamUniqueness === "referenceIds") {
          // For reference ID based hierarchies, check parent_team_reference_id
          if (!team.parent_team_reference_id && team.team) {
            topTeamsSet.add(this.teamKey(team));
          }
        } else {
          // For name-based hierarchies, check parent_team
          if (!team.parent_team && team.team) {
            topTeamsSet.add(this.teamKey(team));
          }
        }
      });
      debug(`topTeamsSet:`, topTeamsSet);

      if (
        topTeamsSet.size == 0 ||
        (this.treeType == TREE_TYPES.TEAMS_ONLY &&
          hasGrandparentTeams &&
          this.customSettings.teamUniqueness != "referenceIds")
      ) {
        topTeamsSet.clear();

        if (this.extraLevels.length > 0) {
          // Get the highest extra level
          const highestLevel = Math.max(...this.extraLevels);
          // Use the highest extra level teams as top level
          allTeams.forEach((team) => {
            // console.log(`team:`, team);
            if (team.team && team[`extra_level_${highestLevel}`]) {
              topTeamsSet.add(team[`extra_level_${highestLevel}`]);
            }
          });
        } else {
          // Fallback to using grandparent teams if no extra levels
          allTeams.forEach((team) => {
            if (team.team && team.grandparent_team) {
              topTeamsSet.add(team.grandparent_team);
            }
          });
        }
      }
      const topTeamsArray = Array.from(topTeamsSet);
      debug(`topTeamsArray:`, topTeamsSet);
      debug(`allTeams:`, allTeams);

      // Convert the Set to an Array of team objects
      const topTeams = topTeamsArray
        .map((teamKey) =>
          allTeams.find((team) => this.teamKey(team) === teamKey),
        )
        .filter((team) => team); // Filter out undefined if any

      debug(`topTeams:`, topTeams);

      let teamIdCounter = 1;
      const visited = new Set();
      const customSettings = this.customSettings;

      // Recursive function to build the hierarchy
      function buildHierarchy(
        team,
        depth = 0,
        parent_id = null,
        parent_reference_id = null,
      ) {
        const temp_id = `T${teamIdCounter++}`;

        // Detect circular references
        const teamKey = getTeamKey(team);
        if (visited.has(teamKey)) {
          // console.warn(`Circular reference detected for team: ${team.team}`);
          return null;
        }
        visited.add(teamKey);

        // Find sub-teams based on parent_team or reference_id
        const subTeams = allTeams
          .filter((sub) => {
            // If we have reference IDs, use them
            if (team.reference_id && sub.parent_team_reference_id) {
              return sub.parent_team_reference_id === team.reference_id;
            }

            // Otherwise, fall back to team name matching
            if (sub.parent_team === team.team) {
              return true;
            }

            // Check grandparent relationship
            if (sub.grandparent_team === team.team) {
              const intermediateTeam = allTeams.find(
                (t) => t.team === sub.parent_team,
              );
              if (!intermediateTeam) {
                return true;
              }
            }

            return false;
          })
          .map((sub) =>
            buildHierarchy(sub, depth + 1, temp_id, team.reference_id),
          )
          .filter((subTeam) => !!subTeam);

        const originalTeam = teamMap.get(teamKey);

        const members =
          (originalTeam ? originalTeam.members : team.members) || [];

        if (team.mgr_email && customSettings?.includeManagersAsOwnTeamMembers) {
          const email = team.mgr_email.trim();
          if (email) members.push(email);
        }

        const teamToReturn = {
          name: team.team,
          team: team.team,
          reference_id: team.reference_id || null,
          parent_team: team.parent_team || null,
          parent_team_reference_id: parent_reference_id || null,
          mgr_email: team.mgr_email || null,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          members: members.filter((member) => member && member.length > 0),
          subTeams: subTeams.sort((a, b) => {
            if (a.is_parent !== b.is_parent) {
              return b.is_parent - a.is_parent;
            }
            return a.team.localeCompare(b.team);
          }),
        };

        return teamToReturn;
      }

      // Step 4: Build the hierarchy starting from top-level teams
      const hierarchy = topTeams
        .map((team) => buildHierarchy(team))
        .filter((team) => team);

      const minGroupSize = this.customSettings.minGroupSize;
      const prunedTeams = [];

      // Prune small leaf teams
      function pruneSmallTeams(node, parent = null) {
        if (!node) return null;

        // Process subTeams first
        if (node.subTeams && node.subTeams.length > 0) {
          node.subTeams = node.subTeams
            .map((subTeam) => pruneSmallTeams(subTeam, node))
            .filter((subTeam) => subTeam !== null);
        }

        // After processing subTeams, check if current node is a leaf
        const isLeaf = !node.subTeams || node.subTeams.length === 0;

        if (isLeaf && node.members.length < minGroupSize) {
          if (parent) {
            // Initialize parent.members as array if needed
            if (!Array.isArray(parent.members)) {
              parent.members = [];
            }

            node.members.forEach((member) => {
              if (member && !parent.members.includes(member)) {
                parent.members.push(member);
              }
            });

            // Record the pruned team
            prunedTeams.push({
              removedTeam: node.team,
              movedMemberCount: node.members.length,
              movedMembers: node.members,
              newSourceTeam: parent.team,
            });

            // Remove this node by returning null
            return null;
          } else {
            // If there's no parent, we cannot prune this team
            return node;
          }
        } else {
          // Update is_parent flag based on subTeams
          node.is_parent = node.subTeams && node.subTeams.length > 0;
          return node;
        }
      }

      // Bind the function to access 'this'
      const boundPruneSmallTeams = pruneSmallTeams.bind(this);

      let finalHierarchy;
      // Apply pruning to the hierarchy
      if (minGroupSize > 0) {
        finalHierarchy = hierarchy
          .map((team) => boundPruneSmallTeams(team))
          .filter((team) => team !== null);
      } else {
        finalHierarchy = hierarchy;
      }

      this.customSettings.prunedTeams = prunedTeams.sort((a, b) =>
        a.removedTeam.localeCompare(b.removedTeam),
      );

      const sortedHierarchy = finalHierarchy.sort((a, b) => {
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        return a.team.localeCompare(b.team);
      });

      return sortedHierarchy;
    },
    generateAllTeams(teams) {
      const teamsMap = new Map();
      const getTeamKey = this.teamKey.bind(this);

      // Helper function to safely add a team to the map
      const addTeamToMap = (team) => {
        if (!team?.team) return; // Skip if team name is missing
        const key = getTeamKey(team);
        if (!teamsMap.has(key)) {
          teamsMap.set(key, team);
        }
      };

      teams.forEach((team) => {
        // Add leaf team
        addTeamToMap(team);

        // Add parent team
        const parentTeam = {
          team: team.parent_team,
          mgr_email: team.parent_team_dri,
          reference_id: team.parent_team_reference_id,
          parent_team: team.grandparent_team,
          parent_team_dri: team.grandparent_team_dri,
          parent_team_reference_id: team.grandparent_team_reference_id,
        };
        addTeamToMap(parentTeam);

        // Add grandparent team
        const grandParentTeam = {
          team: team.grandparent_team,
          mgr_email: team.grandparent_team_dri,
          reference_id: team.grandparent_team_reference_id,
          parent_team: team.extra_level_1,
          parent_team_dri: team.extra_level_1_dri,
          parent_team_reference_id: team.extra_level_1_reference_id,
        };
        addTeamToMap(grandParentTeam);

        // Add extra level teams
        let previousLevel = grandParentTeam;
        this.extraLevels.forEach((level) => {
          const extraTeam = {
            team: team[`extra_level_${level}`],
            mgr_email: team[`extra_level_${level}_dri`],
            reference_id: team[`extra_level_${level}_reference_id`],
            parent_team: team[`extra_level_${level + 1}`],
            parent_team_dri: team[`extra_level_${level + 1}_dri`],
            parent_team_reference_id:
              team[`extra_level_${level + 1}_reference_id`],
          };

          // Only add if we have a valid team name and haven't created a circular reference
          if (extraTeam.team && extraTeam.team !== previousLevel?.team) {
            addTeamToMap(extraTeam);
            previousLevel = extraTeam;
          }
        });
      });

      return Array.from(teamsMap.values()).filter((team) => team.team); // Filter out any invalid teams
    },
    insertMissingParents(teams, teamMap) {
      const getTeamKey = this.teamKey.bind(this);
      const allTeams = [...teams];

      let addedNewTeam;

      do {
        addedNewTeam = false;
        const currentTeams = [...allTeams];

        currentTeams.forEach((team) => {
          // Handle missing parent_team
          if (
            team.parent_team &&
            !teamMap.has(
              getTeamKey({
                team: team.parent_team,
                mgr_email: team.parent_team_dri,
                parent_team: team.grandparent_team,
                parent_team_reference_id: team.grandparent_team_reference_id,
                reference_id: team.parent_team_reference_id,
              }),
            )
          ) {
            if (team.grandparent_team) {
              // Create a placeholder for the missing parent_team
              const parentTeam = {
                team: team.parent_team,
                reference_id: team.parent_team_reference_id,
                mgr_email: team.parent_team_dri,
                parent_team: team.grandparent_team,
                parent_team_dri: team.grandparent_team_dri,
                parent_team_reference_id: team.grandparent_team_reference_id,
                grandparent_team: null,
                members: [],
              };
              // Add the new parent team only if it hasn't been added already
              if (!teamMap.has(getTeamKey(parentTeam))) {
                allTeams.push(parentTeam);
                teamMap.set(getTeamKey(parentTeam), parentTeam);
                addedNewTeam = true;
                // debug(
                //   `Added missing parent team: ${parentTeam.team}`,
                //   parentTeam,
                // );
              }

              // Additionally, ensure that the grandparent_team exists
              if (
                parentTeam.parent_team &&
                !teamMap.has(
                  getTeamKey({
                    team: parentTeam.parent_team,
                    mgr_email: parentTeam.parent_team_dri,
                    parent_team: parentTeam?.grandparent_team || null,
                  }),
                )
              ) {
                const grandparentTeam = {
                  team: parentTeam.parent_team,
                  reference_id: team.grandparent_team_reference_id,
                  mgr_email: parentTeam.parent_team_dri,
                  parent_team: null,
                  grandparent_team: null,
                  members: [],
                };
                if (!teamMap.has(getTeamKey(grandparentTeam))) {
                  allTeams.push(grandparentTeam);
                  teamMap.set(getTeamKey(grandparentTeam), grandparentTeam);
                  addedNewTeam = true;
                  // debug(
                  //   `Added grandparent team: ${grandparentTeam.team}`,
                  //   grandparentTeam,
                  //   `parent_team`,
                  //   parentTeam,
                  // );
                }
              }
            } else {
              // If there's no grandparent_team, consider parent_team as a top-level team
              const parentTeam = {
                team: team.parent_team,
                reference_id: team.parent_team_reference_id,
                mgr_email: team.parent_team_dri,
                parent_team: null,
                grandparent_team: null,
                members: [],
              };
              // At one point, I had an additional check for hasGrandparentTeams, but it was causing issues
              // I ended up switching from !hasGrandparentTeams and back to hasGrandparentTeams, realizing that both are relevant.
              if (!teamMap.has(getTeamKey(parentTeam))) {
                allTeams.push(parentTeam);
                teamMap.set(getTeamKey(parentTeam), parentTeam);
                addedNewTeam = true;
                // debug(`Added top-level parent team: ${parentTeam.team}`);
              }
            }
          }

          // Handle missing grandparent_team separately
          if (
            team.grandparent_team &&
            !teamMap.has(
              getTeamKey({
                team: team.grandparent_team,
                mgr_email: team.grandparent_team_dri,
                parent_team: null,
              }),
            )
          ) {
            const grandparentTeam = {
              team: team.grandparent_team,
              reference_id: team.grandparent_team_reference_id,
              mgr_email: team.grandparent_team_dri,
              parent_team: team[`extra_level_1`],
              grandparent_team: team[`extra_level_2`],
              members: [],
            };
            if (!teamMap.has(getTeamKey(grandparentTeam))) {
              allTeams.push(grandparentTeam);
              teamMap.set(getTeamKey(grandparentTeam), grandparentTeam);
              addedNewTeam = true;
            }
          }

          // Handle missing extra level teams
          this.extraLevels.forEach((level) => {
            const extraLevelTeam = team[`extra_level_${level}`];
            const extraLevelDri = team[`extra_level_${level}_dri`];
            const extraLevelReferenceId =
              team[`extra_level_${level}_reference_id`];

            if (
              extraLevelTeam &&
              !teamMap.has(
                getTeamKey({
                  team: extraLevelTeam,
                  mgr_email: extraLevelDri,
                  reference_id: extraLevelReferenceId,
                }),
              )
            ) {
              const newExtraLevelTeam = {
                team: extraLevelTeam,
                reference_id: extraLevelReferenceId,
                mgr_email: extraLevelDri,
                parent_team: null,
                grandparent_team: null,
                members: [],
              };
              if (!teamMap.has(getTeamKey(newExtraLevelTeam))) {
                allTeams.push(newExtraLevelTeam);
                teamMap.set(getTeamKey(newExtraLevelTeam), newExtraLevelTeam);
                addedNewTeam = true;
                // debug(`Added extra level ${level} team: ${newExtraLevelTeam.team}`, newExtraLevelTeam,);
              }
            }
          });
        });
      } while (addedNewTeam);

      return allTeams;
    },
    toggleSquad(targetSquad) {
      this.squads = this.squads.map((squad) => {
        if (targetSquad.temp_id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
        }

        if (targetSquad.temp_id === squad.temp_id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (targetSquad.expanded) {
            this.expandedSquadsIds[targetSquad.temp_id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.temp_id];
          }
        }

        return squad;
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.temp_id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    collapseAll() {
      this.squads.forEach((squad) => {
        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    recalculateTree() {
      this.preview();
    },
    submit(markAsPreview = false, staged = false) {
      if (!this.reviewing && !markAsPreview) {
        this.startReview();
        return;
      }
      if (!staged) {
        this.progressPayload = {};
        debug(`calling goToSubmitting...`);
        this.goToSubmitting();
      }

      const url = "/admin/org_csvs";
      debug(`rows:`, this.parsedRows);

      const params = {
        squads: this.squads,
        associations_only: this.customSettings.associationsOnly,
        user_memberships_only: this.customSettings.userMembershipsOnly,
        tree_type: this.treeType,
        reset_all_teams: this.customSettings.resetAllTeams,
        rows: this.parsedRows,
        save_for_preview: markAsPreview,
        team_uniqueness: this.customSettings.teamUniqueness,
        staged: staged,
      };
      debug(`params:`, params);

      postData(url, params).then((res) => {
        debug(`res:`, res);
        if (res.import_id) {
          this.activeImportId = res.import_id;
          this.activeImportEncodedId = res.import_encoded_id;
          if (!markAsPreview) {
            this.poll();
          } else {
            window.location.href = `/admin/org_csvs/preview/${this.activeImportEncodedId}`;
          }
        }
      });
    },
    updateImport() {
      const url = `/admin/org_csvs/${this.activeImportId}`;
      const payload = {
        customer_approved_at: new Date().toISOString(),
        squads: this.squads,
        team_uniqueness: this.customSettings.teamUniqueness,
      };
      putData(url, payload).then((res) => {
        if (res.success) {
          this.activePreviewableImport = {
            ...this.activePreviewableImport,
            customer_approved_at: new Date().toISOString(),
          };
        }
      });
    },
    poll() {
      if (!this.activeImportId) return;
      const fullUrl = `/admin/org_csvs/${this.activeImportId}/progress`;
      this.intervalId = setInterval(async () => {
        try {
          const response = await fetch(fullUrl);
          const data = await response.json();

          this.progress = (data.items_processed / data.total_items) * 100 || 0;
          debug(`progress:`, this.progress);
          this.progressPayload = data;
          debug(`progressPayload:`, this.progressPayload);

          if (data.completion_report) {
            this.updateSquadsWithExistingIds(
              data.completion_report.updated_teams,
            );
            this.hierarchyGroups = data.hierarchy_groups;
            this.levelsSummary = data.levels_summary;
          }

          // Stop polling if job is complete
          if (data.completion_report || this.progressPayload.failed_at) {
            clearInterval(this.intervalId);
          }
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      }, 1000); // NOTE: POLLING INTERVAL
    },
    downloadSourceCsv() {
      // Convert rows_json back to CSV format
      const rows = this.parsedRows;
      console.log(`rows:`, rows);
      const headers = Object.keys(rows[0]);

      // Create CSV string starting with headers
      let csv = headers.join(",") + "\n";

      // Add each row of data
      rows.forEach((row) => {
        const values = headers.map((header) => {
          const value = row[header];
          // Handle values that need quotes (contain commas or quotes)
          if (value && (value.includes(",") || value.includes('"'))) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value || "";
        });
        csv += values.join(",") + "\n";
      });

      const blob = new Blob([csv], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "source.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    downloadJSON() {
      const json = JSON.stringify(this.squads, null, 2); // Convert squads to JSON
      const blob = new Blob([json], { type: "application/json" }); // Create a Blob
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href to the Blob URL
      a.download = "squads.json"; // Set the download attribute
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor from the document
      URL.revokeObjectURL(url); // Clean up the URL object
    },
    uniqueMembers(data) {
      const uniqueMembers = data.reduce((accumulator, squad) => {
        // Check if members exist and is an array
        if (Array.isArray(squad.members)) {
          // Add each member to the accumulator
          squad.members.forEach((member) => {
            if (
              member &&
              typeof member === "string" &&
              member.trim().length > 0
            ) {
              accumulator.add(member); // Use a Set to store unique values
            }
          });
        }
        return accumulator;
      }, new Set());

      return uniqueMembers;
    },
    startReview() {
      this.goToReviewing();

      this.reviewData = {
        totalTeams: this.squads.length,
        newTeams: this.squads.filter(
          (s) => !this.preprocessedTeams.find((p) => p.team === s.team),
        ),
        modifiedTeams: [], // You can add logic to track modified teams
        totalMembers: Array.from(this.uniqueMembers(this.squads)).length,
        unassignedMembers: this.userDiff,
      };
      // TODO: Uncomment when ready to start generating reports again
      const markAsPreview = false;
      const staged = true;
      if (
        !this.activeReviewableImport &&
        !this.currentCompletionReport &&
        !this.currentCompletionReport?.updated_teams.length
      ) {
        this.submit(markAsPreview, staged);
      }

      if (
        this.activeReviewableImport &&
        this.currentCompletionReport?.updated_teams.length
      ) {
        this.updateSquadsWithExistingIds(
          this.currentCompletionReport?.updated_teams,
        );
      }
    },
    get groupedUpdatedTeams() {
      if (!this.currentCompletionReport?.updated_teams.length) return [];

      // Group teams by match_reason
      const grouped = this.currentCompletionReport.updated_teams.reduce(
        (acc, team) => {
          const reason = team.match_reason || "unknown";
          if (!acc[reason]) {
            acc[reason] = [];
          }
          acc[reason].push(team);
          return acc;
        },
        {},
      );

      // Convert to array and sort by match_tier of first team in each group
      const result = Object.entries(grouped)
        .map(([reason, teams]) => ({
          reason,
          teams,
          tier: teams[0]?.match_tier ?? Infinity, // Use first team's tier, default to Infinity if no tier
        }))
        .sort((a, b) => a.tier - b.tier); // Sort by tier

      return result;
    },
    updateSquadsWithExistingIds(updatedTeams) {
      const tempIdMap = new Map(
        updatedTeams
          .filter((team) => team.existing_id)
          .map((team) => [team.temp_id, team]),
      );

      this.squads = this.squads.map((squad) => {
        const updatedTeam = tempIdMap.get(squad.temp_id);
        return updatedTeam ? { ...squad, ...updatedTeam } : squad;
      });
    },
    goToManipulating() {
      this.step = STEPS.MANIPULATING;
      this.changeRoute("new");

      // Find supervisory org columns and their levels
      const supervisoryOrgCols = this.headers.filter((header) =>
        header.toLowerCase().includes("supervisory_org"),
      );
      const managerCols = this.headers.filter(
        (header) =>
          header.toLowerCase().includes("manager") &&
          header.toLowerCase().includes("level") &&
          !header.toLowerCase().includes("name"), // Exclude name columns
      );

      if (supervisoryOrgCols.length) {
        // Extract level numbers using regex for names and IDs separately
        const namePattern = /supervisory_org_name.*level_(\d+)/i;
        const idPattern = /supervisory_org_id.*level_(\d+)/i;
        const managerPattern = /manager.*level[_-]?(\d+)/i; // More flexible pattern
        const namesMap = new Map();
        const idsMap = new Map();
        const managersMap = new Map();

        supervisoryOrgCols.forEach((col) => {
          const nameMatch = col.toLowerCase().match(namePattern);
          const idMatch = col.toLowerCase().match(idPattern);

          if (nameMatch) {
            const levelNum = parseInt(nameMatch[1], 10);
            namesMap.set(levelNum, col);
          } else if (idMatch) {
            const levelNum = parseInt(idMatch[1], 10);
            idsMap.set(levelNum, col);
          }
        });

        managerCols.forEach((col) => {
          const match = col.toLowerCase().match(managerPattern);
          if (match) {
            const levelNum = parseInt(match[1], 10);
            managersMap.set(levelNum, col);
          }
        });

        if (namesMap.size > 0) {
          const nameLevels = Array.from(namesMap.keys()).sort((a, b) => a - b);
          const minNameLevel = nameLevels[0];
          const maxNameLevel = nameLevels[nameLevels.length - 1];
          this.teamNamesStart = namesMap.get(minNameLevel);
          this.teamNamesEnd = namesMap.get(maxNameLevel);
        }

        if (idsMap.size > 0) {
          const idLevels = Array.from(idsMap.keys()).sort((a, b) => a - b);
          const minIdLevel = idLevels[0];
          const maxIdLevel = idLevels[idLevels.length - 1];
          this.teamIDsStart = idsMap.get(minIdLevel);
          this.teamIDsEnd = idsMap.get(maxIdLevel);
        }

        if (managersMap.size > 0) {
          const managerLevels = Array.from(managersMap.keys()).sort(
            (a, b) => a - b,
          );
          const minManagerLevel = managerLevels[0];
          const maxManagerLevel = managerLevels[managerLevels.length - 1];
          this.teamDRIsStart = managersMap.get(minManagerLevel);
          this.teamDRIsEnd = managersMap.get(maxManagerLevel);
        }

        // Trigger the preview update if we found any matches
        if (namesMap.size > 0 || idsMap.size > 0 || managersMap.size > 0) {
          this.updateManipulatedPreview();
        }
      }
    },
    reverseTeamNamesRange() {
      [this.teamNamesStart, this.teamNamesEnd] = [
        this.teamNamesEnd,
        this.teamNamesStart,
      ];
      this.updateManipulatedPreview();
    },
    reverseTeamDRIsRange() {
      [this.teamDRIsStart, this.teamDRIsEnd] = [
        this.teamDRIsEnd,
        this.teamDRIsStart,
      ];
      this.updateManipulatedPreview();
    },
    reverseTeamIDsRange() {
      [this.teamIDsStart, this.teamIDsEnd] = [
        this.teamIDsEnd,
        this.teamIDsStart,
      ];
      this.updateManipulatedPreview();
    },
    getColumnRange(startCol, endCol) {
      const startIndex = this.headers.indexOf(startCol);
      const endIndex = this.headers.indexOf(endCol);

      if (startIndex === -1 || endIndex === -1) return [];

      const start = Math.min(startIndex, endIndex);
      const end = Math.max(startIndex, endIndex);

      return this.headers.slice(start, end + 1);
    },
    updateManipulatedPreview() {
      const teamNameCols = this.getColumnRange(
        this.teamNamesStart,
        this.teamNamesEnd,
      );
      const teamDRICols = this.getColumnRange(
        this.teamDRIsStart,
        this.teamDRIsEnd,
      );
      const teamIDCols = this.getColumnRange(
        this.teamIDsStart,
        this.teamIDsEnd,
      );

      this.manipulatedRows = this.rows.map((row) => {
        // Start with all original fields
        const newRow = { ...row };

        // Add our standard mapped fields with empty defaults
        const mappedFields = {
          team_name: "",
          mgr_email: "",
          parent_team: "",
          parent_team_dri: "",
          grandparent_team: "",
          grandparent_team_dri: "",
          team_reference_id: "",
          parent_team_reference_id: "",
          grandparent_team_reference_id: "",
        };

        // Merge the original data with our mapped fields
        Object.assign(newRow, mappedFields);

        // Process team names
        let nonEmptyTeams = teamNameCols
          .map((col) => row[col]?.trim())
          .filter((name) => name !== "");

        if (
          this.headers.indexOf(this.teamNamesEnd) <
          this.headers.indexOf(this.teamNamesStart)
        ) {
          nonEmptyTeams = nonEmptyTeams.reverse();
        }

        // Apply offset to the mapping
        const teamOffset = this.teamNamesOffset || 0;
        nonEmptyTeams.forEach((teamName, index) => {
          const adjustedIndex = index + teamOffset;
          const teamKey =
            adjustedIndex === 0
              ? "team_name"
              : adjustedIndex === 1
                ? "parent_team"
                : adjustedIndex === 2
                  ? "grandparent_team"
                  : `extra_level_${adjustedIndex - 2}`;
          newRow[teamKey] = teamName;
        });

        // Process team DRIs
        let nonEmptyDRIs = teamDRICols
          .map((col) => row[col]?.trim())
          .filter((dri) => dri !== "");

        if (
          this.headers.indexOf(this.teamDRIsEnd) <
          this.headers.indexOf(this.teamDRIsStart)
        ) {
          nonEmptyDRIs = nonEmptyDRIs.reverse();
        }

        const driOffset = this.teamDRIsOffset || 0;
        nonEmptyDRIs.forEach((dri, index) => {
          const adjustedIndex = index + driOffset;
          const driKey =
            adjustedIndex === 0
              ? "mgr_email"
              : adjustedIndex === 1
                ? "parent_team_dri"
                : adjustedIndex === 2
                  ? "grandparent_team_dri"
                  : `extra_level_${adjustedIndex - 2}_dri`;
          newRow[driKey] = dri;
        });

        // Process team IDs
        let nonEmptyIDs = teamIDCols
          .map((col) => row[col]?.trim())
          .filter((id) => id !== "");

        if (
          this.headers.indexOf(this.teamIDsEnd) <
          this.headers.indexOf(this.teamIDsStart)
        ) {
          nonEmptyIDs = nonEmptyIDs.reverse();
        }

        const idOffset = this.teamIDsOffset || 0;
        nonEmptyIDs.forEach((id, index) => {
          const adjustedIndex = index + idOffset;
          const idKey =
            adjustedIndex === 0
              ? "team_reference_id"
              : adjustedIndex === 1
                ? "parent_team_reference_id"
                : adjustedIndex === 2
                  ? "grandparent_team_reference_id"
                  : `extra_level_${adjustedIndex - 2}_reference_id`;
          newRow[idKey] = id;
        });

        return newRow;
      });

      this.sampledRows = this.manipulatedRows.slice(0, 20);
    },
    processManipulatedData() {
      // Update the main rows with the manipulated data
      this.rows = this.manipulatedRows;
      console.log(`manipulatedRows:`, this.manipulatedRows);

      // Auto-map the columns based on our new structure
      this.selectedColumns = {
        email: "email",
        team_name: "team_name",
        team_reference_id: "team_reference_id",
        mgr_email: "mgr_email",
        parent_team: "parent_team",
        parent_team_reference_id: "parent_team_reference_id",
        parent_team_dri: "parent_team_dri",
        grandparent_team: "grandparent_team",
        grandparent_team_reference_id: "grandparent_team_reference_id",
        grandparent_team_dri: "grandparent_team_dri",
      };

      // Only add extra levels that actually have data
      const extraLevelKeys = Object.keys(this.sampledRows[0] || {})
        .filter(
          (key) =>
            key.startsWith("extra_level_") &&
            !key.includes("dri") &&
            !key.includes("reference"),
        )
        .map((key) => parseInt(key.split("_")[2]));

      this.extraLevels = extraLevelKeys;

      // Run the traditional autoMapColumns to catch any additional fields
      this.headers = Object.keys(this.sampledRows[0]);
      this.sampledRows = this.sampleRandomRows();
      this.autoMapColumns();

      this.treeType = TREE_TYPES.TEAM;
      // Move to the mapping step
      this.goToMapping();
    },
    shiftTeamNamesUp() {
      this.teamNamesOffset = (this.teamNamesOffset || 0) + 1;
      this.updateManipulatedPreview();
    },
    shiftTeamNamesDown() {
      this.teamNamesOffset = (this.teamNamesOffset || 0) - 1;
      if (this.teamNamesOffset < 0) this.teamNamesOffset = 0;
      this.updateManipulatedPreview();
    },
    shiftTeamDRIsUp() {
      this.teamDRIsOffset = (this.teamDRIsOffset || 0) + 1;
      this.updateManipulatedPreview();
    },
    shiftTeamDRIsDown() {
      this.teamDRIsOffset = (this.teamDRIsOffset || 0) - 1;
      if (this.teamDRIsOffset < 0) this.teamDRIsOffset = 0;
      this.updateManipulatedPreview();
    },
    shiftTeamIDsUp() {
      this.teamIDsOffset = (this.teamIDsOffset || 0) + 1;
      this.updateManipulatedPreview();
    },
    shiftTeamIDsDown() {
      this.teamIDsOffset = (this.teamIDsOffset || 0) - 1;
      if (this.teamIDsOffset < 0) this.teamIDsOffset = 0;
      this.updateManipulatedPreview();
    },
    unlinkTeam(team) {
      // A) Move team from updated to created in completion report
      if (this.currentCompletionReport?.updated_teams) {
        const updatedTeamIndex =
          this.currentCompletionReport.updated_teams.findIndex(
            (t) => t.temp_id === team.temp_id,
          );

        if (updatedTeamIndex !== -1) {
          // Remove from updated_teams
          const [removedTeam] =
            this.currentCompletionReport.updated_teams.splice(
              updatedTeamIndex,
              1,
            );

          // Add to created_teams (initialize if needed)
          this.currentCompletionReport.created_teams =
            this.currentCompletionReport.created_teams || [];
          // Add to beginning of array instead of end
          this.currentCompletionReport.created_teams.unshift({
            ...removedTeam,
            force_creation: true,
          });
        }
      }

      // B) Update the team in squads array
      this.squads = this.squads.map((squad) => {
        if (squad.temp_id === team.temp_id) {
          // Create new team object without existing_* fields
          const cleanedTeam = Object.keys(squad)
            .filter((key) => !key.startsWith("existing_"))
            .reduce((obj, key) => {
              obj[key] = squad[key];
              return obj;
            }, {});

          // Add force_creation flag
          return {
            ...cleanedTeam,
            force_creation: true,
          };
        }
        return squad;
      });
    },
    async openLinkTeamModal(team) {
      this.teamToLink = team;
      this.showLinkTeamModal = true;
      this.teamSearchQuery = "";

      try {
        const response = await fetch("/admin/org_csvs/teams_list", {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        });
        if (!response.ok) throw new Error("Failed to fetch teams");
        const data = await response.json();
        this.existingTeams = data;
        this.filteredExistingTeams = data;
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    },
    filterTeams() {
      if (!this.teamSearchQuery) {
        this.filteredExistingTeams = this.existingTeams;
        return;
      }

      const query = this.teamSearchQuery.toLowerCase();
      this.filteredExistingTeams = this.existingTeams.filter(
        (team) =>
          team.name.toLowerCase().includes(query) ||
          (team.parent_team_name &&
            team.parent_team_name.toLowerCase().includes(query)) ||
          (team.manager_email &&
            team.manager_email.toLowerCase().includes(query)),
      );
    },
    selectTeamToLink(existingTeam) {
      this.linkTeam(this.teamToLink, existingTeam);
      this.showLinkTeamModal = false;
      this.teamToLink = null;
      this.teamSearchQuery = "";
    },
    linkTeam(teamToLink, existingTeam) {
      // Move team from created to updated in completion report
      if (this.currentCompletionReport?.created_teams) {
        const createdTeamIndex =
          this.currentCompletionReport.created_teams.findIndex(
            (t) => t.temp_id === teamToLink.temp_id,
          );

        if (createdTeamIndex !== -1) {
          // Remove from created_teams
          const [removedTeam] =
            this.currentCompletionReport.created_teams.splice(
              createdTeamIndex,
              1,
            );

          // Add to updated_teams (initialize if needed)
          this.currentCompletionReport.updated_teams =
            this.currentCompletionReport.updated_teams || [];

          // Add existing team data
          const updatedTeam = {
            ...removedTeam,
            existing_id: existingTeam.id,
            existing_name: existingTeam.name,
            existing_parent_name: existingTeam.parent_team_name,
            existing_manager_email: existingTeam.manager_email,
            match_reason: "Manually linked by user",
            match_tier: 1,
          };

          this.currentCompletionReport.updated_teams.push(updatedTeam);
        }
      }

      // Create a new array with the updated squad
      const updatedSquads = this.squads.map((squad) => {
        if (squad.temp_id === teamToLink.temp_id) {
          return {
            ...squad,
            existing_id: existingTeam.id,
            existing_name: existingTeam.name,
            existing_parent_name: existingTeam.parent_team_name,
            existing_manager_email: existingTeam.manager_email,
            match_reason: "Manually linked by user",
            match_tier: 1,
          };
        }
        return squad;
      });

      // Update the squads array with the new array
      this.squads = updatedSquads;
    },
  };
}
